@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    font-family: "Montserrat", sans-serif;
  }

  .pattern-bg {
    background-image: url("./resources/mf_background_pattern_tr.png");
    background-repeat: space;
    background-size: 100px;
  }
}

@layer components {
  img {
    display: flex;
    object-fit: contain;
    width: auto;
    height: 100%;
    user-select: none;
    pointer-events: none;
  }

  .coin-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 295px;
    height: 295px;
    filter: drop-shadow(40px 30px 30px rgba(7, 14, 29, 0.4));
  }

  .coin {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    position: relative;
    transform-style: preserve-3d;
    transform: rotateY(0deg);
    animation: token-rotate 4s infinite linear;
  }
  .coin:before,
  .coin:after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, #000000, #000000);
    border-radius: 50%;
    transform-style: preserve-3d;
  }
  .coin::before {
    transform: translate3d(-50%, 0, calc((10px / 2) - 1px));
  }
  .coin::after {
    transform: translate3d(-50%, 0, calc((10px / -2) + 1px)) rotateY(180deg);
  }

  .coin__side {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 10;
  }

  .coin__side--front {
    transform: translate3d(-50%, 0, calc(10px / 2));
  }

  .coin__side--back {
    transform: translate3d(-50%, 0, calc(10px / -2)) rotateY(180deg);
  }

  .coin__image {
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border-radius: 50%;
    @apply bg-mk-gray;
  }

  .coin__edge {
    position: absolute;
    min-height: 100%;
    min-width: 10px;
    background: linear-gradient(
      90deg,
      transparent,
      transparent 1%,
      #000000 1%,
      #000000 99%,
      transparent 99%,
      transparent 100%
    );
    background-repeat: no-repeat;
    border-left: 1px inset transparent;
    border-right: 1px inset transparent;
    transform-style: preserve-3d;
    transform: rotateY(90deg);
    z-index: 20;
  }

  @keyframes token-rotate {
    0% {
      transform: rotateY(0deg);
    }
    100% {
      transform: rotateY(360deg);
    }
  }
}
